import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import { CustomScroll } from "react-custom-scroll";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import Select from "react-select";
import { useRates } from "../../../providers/Rates/RatesProvider";
import { countries } from "../../../common/countries";
import api from "../../../api";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../providers/AuthProvider";
import { usePaymentMethods } from "../../../providers/PaymentMethodsProvider";
import "./payment_methods.scss";
import successIcon           from "../../../assets/successful.svg"; 

const AddPhoneNumber = ({ modelContent, sideModel, openModelFunc }) => {

  const { authTokens } = useAuth();
  const { currencies } = useRates();
  const { reloadPaymentMethods } = usePaymentMethods();
  const [selectedTab, setSelectedTab] = useState("my account");
  const [selectedAccountDetails, setSelectedAccountDetails] = useState("");
  const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [successPosted, setSuccessPosted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [dataPlaceState, setDataPlaceState] = useState({
                                                          accountName: "",
                                                          accountNumber: "",
                                                          region: "",
                                                          city: "",
                                                          address: "",
                                                          buildingNumber: "",
                                                          phonenumber: "",
                                                          phonenumberLabel: "",
                                                          country: "",
                                                          currency: "",
                                                          type: "PHONE_NUMBER",
                                                          currencyOptions: [],
                                                          countries: [],
                                                        });

  const defaultCurrency = (modelContent?.data?.currency !== undefined)? modelContent?.data?.currency : "UGX";
  const { register, handleSubmit, formState: { errors } } = useForm();

  const process = useMutation({
    mutationFn: (fields) => api.addPaymentMethodPhonenumber({ paymentMethod: fields, token: authTokens.IdToken }),
    onError: (error) => console.error(error.response.data.errors),
    onSuccess: async (data) => {

      if (data.status === 201 || data.status === 200) {
        await reloadPaymentMethods();
        setSuccessPosted(true);
      }
    },
  });

  const init = async () => {
    try {
      
      const currencyOptions = currencies.filter((currency) => !currency.isCrypto).map((currency) => ({
        value: currency.code,
        label: currency.code,
      }));
      
      if (currencyOptions.length > 0) {

        setDataPlaceState(prevState => ({ ...prevState, currencyOptions }));
				const defaultCurrencyObject =  currencyOptions.filter((option) => option.value === defaultCurrency);
				let selectedCurrencyVal     = defaultCurrencyObject[0];
        setSelectedCurrency(selectedCurrencyVal);

      }
    } catch (error) {
      console.log("Error initializing data:", error);
    }
  };

  const initCountry = async () => {
    try {
      const countryOptions = countries.map((country) => ({
        label: country.name,
        value: country.code,
      }));

      if (countryOptions.length > 0) {
        setDataPlaceState(prevState => ({ ...prevState, countries: countryOptions }));
        const defaultCountry = countries.filter((country) => country?.currency?.code === defaultCurrency);
        setSelectedCountry(countryOptions.find(option => option.value === defaultCountry[0]?.code));
      }
    } catch (error) {
      console.log("Error initializing data:", error);
    }
  };

  useEffect(() => {
    init();
    initCountry();
  }, []);

  const handleToggleTabs = (options) => {
    if (options?.payment_type) setSelectedTab(options.payment_type);
  };

  const handleToggleAccountDetails = (options) => {
    setSelectedAccountDetails(prev => (prev === options?.account_id ? "" : options?.account_id));
  };

  const handleToggleAccountMoreDetails = (options) => {
    setSelectedAccountMoreDetails(prev => (prev === options?.account_id ? "" : options?.account_id));
  };

  const handleModelClose = () => {
    if (modelContent?.operation_method_type_step_2) {
      openModelFunc({ operation_type: modelContent?.operation_type_step_2, operation_method_type: modelContent?.operation_method_type_step_2, data: modelContent?.data, reloadContent: true });
    } else {
      openModelFunc();
    }
  };



  const changeDefaultCountry = (options) => {
    setSelectedCountry(options);
    init();
  };

  const changeDefaultCurrency = (options) => {
    setSelectedCurrency(options);
  };

  const changePhoneNumber = (fieldVal, value) => {
    setDataPlaceState((prevState) => ({
      ...prevState,
      [fieldVal]: value,
    }));
  };

  const onSaving = (fields) => {
    const updatedFields = {
      ...fields,
      country: selectedCountry?.value,
      currency: selectedCurrency?.value,
      phonenumber: dataPlaceState?.phonenumber,
      phonenumberLabel: dataPlaceState?.phonenumberLabel,
      type: dataPlaceState?.type,
    };

    process.mutate(updatedFields);
  };

  return (
    <div className="accountsettings_paymentmethods_model">

        <div className={successPosted? "success_form": "hidden"}>
		  <div className="text_center model_title_section text_center">
			<h3 className="text_center"> Phone number added </h3>	
			<h4>Your {(selectedCurrency?.value !== undefined )? selectedCurrency?.value: ' '} phone number was added successfully </h4>
			<img src={successIcon} alt="successful"/>
			<div className="add_new_button column_100"> 
			  <button onClick={() => handleModelClose()}> Ok </button>
			</div>
		  </div>
	  </div>
	  <div className={!successPosted? "": "hidden"}>
		<div className="accountsettings_paymentmethods_model_heading">Add Phone Number</div>
		<div className={sideModel ? "market_bank_deposit_scroll_2 padding_top_30" : ""}>
			<CustomScroll className="custom_scrollbar" heightRelativeToParent="100%" allowOuterScroll="true">
			<div className="form_input_field column_100">
				<label>Currency</label>
				<Select
				value={selectedCurrency}
				className="input_select input-x"
				options={dataPlaceState.currencyOptions}
				{...register("currency", { required: false })}
				onChange={changeDefaultCurrency}
				isSearchable
				/>
				{errors.currency?.type === "required" && <span className="input_error"><Info className="input_error_icon" /> Currency is required </span>}
			</div>

			<div className="form_input_field column_100">
				<label>Country</label>
				<Select
				value={selectedCountry}
				className="input_select input-x"
				options={dataPlaceState.countries}
				{...register("country", { required: false })}
				onChange={changeDefaultCountry}
				isSearchable
				/>
				{errors.country?.type === "required" && <span className="input_error"><Info className="input_error_icon" /> Country is required </span>}
			</div>

			<div className="form_input_field column_100">
				<label className="column_100 text-left">Phone number label</label>
				<input
				className="column_100 input"
				type="text"
				placeholder="Add phone number label"
				value={dataPlaceState.phonenumberLabel}
				name="phonenumberLabel"
				{...register("phonenumberLabel", { required: true })}
				onChange={(e) => changePhoneNumber("phonenumberLabel", e.target.value)}
				/>
				{errors.phonenumberLabel?.type === "required" && <span className="input_error"><Info className="input_error_icon" /> Phone number label is required </span>}
			</div>

			<div className="form_input_field column_100">
				<label className="column_100 text-left">Phone number</label>
				<PhoneInput
				placeholder="Enter phone number"
				value={dataPlaceState.phonenumber || ""}
				className="input"
				defaultCountry="ug"
				name="phonenumber"
				{...register("phonenumber", { required: true })}
				onChange={(e) => changePhoneNumber("phonenumber", e)}
				/>
				{errors.phonenumber?.type === "required" && <span className="input_error"><Info className="input_error_icon" /> Phone number is required </span>}
			</div>
			</CustomScroll>
		</div>

		<div className="add_new_button column_100">
			{process.isPending ? <button><Loader2 className="processing_loading" /></button> : <button onClick={handleSubmit(onSaving)}>Add phone number</button>}
		</div>
	   </div>	
    </div>
  );
};

export default AddPhoneNumber;
