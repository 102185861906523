import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import _ from "lodash";
import { useAuth } from "../../../../providers/AuthProvider";
import api from "../../../../api";

const MyOrdersContext = createContext();
const MyOrdersDispatchContext = createContext();

export const MY_ORDERS_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const MyOrdersProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getMyOrders = useQuery({
		queryKey: ["myOrders"],
		queryFn: () => {
			return api.getMyOrders({ token: authTokens.IdToken });
		},
		enabled: false,
	});


	const fetchMyOrders = async () => {
		myOrdersDispatch({ type: MY_ORDERS_ACTIONS.updateState, key: "isFetchingMyOrders", value: true });
		const myOrdersRefetchResult = await getMyOrders.refetch();
		myOrdersDispatch({ type: MY_ORDERS_ACTIONS.updateState, key: "isFetchingMyOrders", value: false });
		if (myOrdersRefetchResult.status == "success") {

			let myOrders = myOrdersRefetchResult.data.data.data;
        	myOrders = myOrders.map((order) => {
			  try{
				const newOrder  = order.attributes;
                let dateString	= newOrder.date.replace(" - ", "  ");
				let dateParts   = dateString.split(/[/ :]/);
				dateString      = new Date(dateParts[2], dateParts[1] - 1, dateParts[0], dateParts[3] % 12 + (dateParts[5] === 'PM' ? 12 : 0));
				newOrder.createDate = dateString.getTime(); 

				newOrder.id   = newOrder.id.split("-")[0];
				switch (newOrder._rawTxnType) {
					case "order":
						newOrder.type = "order";
						break;
					case "request":
						newOrder.type = "request";
						break;
					default:
						newOrder.type = `N/A :: ${newOrder._rawTxnType}`;
						break;
				}
				return newOrder;
			  }	catch(e){}
			});
			myOrders = myOrders.sort((a, b) => new Date(b?.createDate) - new Date(a?.createDate));
			myOrdersDispatch({ type: MY_ORDERS_ACTIONS.updateState, key: "myOrders", value: myOrders });
		}
	};

	const [myOrdersState, myOrdersDispatch] = useReducer(MyOrdersReducer, {
		myOrders: [],
		isFetchingMyOrders: false,
		fetchMyOrders,
		getMyOrders,
	});

	return (
		<MyOrdersContext.Provider value={myOrdersState}>
			<MyOrdersDispatchContext.Provider value={myOrdersDispatch}>{children}</MyOrdersDispatchContext.Provider>
		</MyOrdersContext.Provider>
	);
};

export const useMyOrders = () => {
	return useContext(MyOrdersContext);
};

export const useMyOrdersDispatch = () => {
	return useContext(MyOrdersDispatchContext);
};

const MyOrdersReducer = (myOrdersState, action) => {
	switch (action.type) {
		case MY_ORDERS_ACTIONS.updateState: {
			const newState = { ...myOrdersState };
			newState[action.key] = action.value;
			return newState;
		}
		case MY_ORDERS_ACTIONS.updateStates: {
			const newState = { ...myOrdersState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[MyOrdersReducer] Unknown action: " + action.type);
		}
	}
};
