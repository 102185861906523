import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import "../../model.scss";
import Select from 'react-select';
import api from "../../../../api";


import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../../providers/AuthProvider";

import { useRates } from "../../../../providers/Rates/RatesProvider";
import successIcon  from "../../../../assets/successful.svg"; 

const AccountWallet = ({openModelFunc}) => {

	const { currencies, walletCurrencies } = useRates();
	const { authTokens, isLoggedIn } = useAuth();
	const [selectedCurrency,  setSelectedCurrency] = useState({});
	const [successPosted,  setSuccessPosted] = useState(false);
	const [marketplaceState, setMarketplaceState]  = useState({ currencyOptions: [] });
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	
	const handleResize = () => {
		setWindowSize({
		  width: window.innerWidth,
		  height: window.innerHeight,
		});
		console.log("Window resized:", window.innerWidth, window.innerHeight);
		// Add other functionality here
	};
	
	useEffect(() => {

		const currencyOptions = walletCurrencies.map((currency) => ({
			value: currency.code,
			label: currency.code,
		}));
		if(currencyOptions.length > 0){

			setMarketplaceState((prevState) => ({
				...prevState,
				currencyOptions: currencyOptions,
			}));
			setSelectedCurrency(currencyOptions[0]);
		}
		

		// Add the resize event listener
		window.addEventListener("resize", handleResize);
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener("resize", handleResize);
		};
	}, []);

	const changeDefaultCurreny = (options) => {
	  setSelectedCurrency(options)
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const process = useMutation({

		mutationFn: (fields) => {
			return api.createWallets({token: authTokens.IdToken, wallet: fields});
		},
		onError: (error, variables, context) => {

            const errors = error.response.data.errors;
		},
		onSuccess: (data, variables, context) => {

		   //Saving response content
		   const authTokens = data.data.data.attributes;
		   if(data.status === 201 || data.status === 200){
			setSuccessPosted(true);
		   }
		},
	});

	const onSaving = (fields) => {

		fields = {
			...fields,
			currency: selectedCurrency?.value
		};
		return;
		
		//process.mutate(fields);
	};

	const closeBlock = () => {
	
	};

	const handleModelClose = (e) => {
	    openModelFunc();
	};

	return (
	  <div className="pop_moddule_pops_model_main walley_container">
		
		<div className={successPosted? "success_form": "hidden"}>
		  <div className="text_center model_title_section text_center">
			<h3 className="text_center"> Account wallet created </h3>	
			<h4>Your {selectedCurrency?.value} account wallet was added successfully </h4>
			<img src={successIcon} alt="successful"/>
			<div className="add_new_button column_100"> 
			   <button onClick={() => handleModelClose()}> Ok </button>
			</div>
		  </div>
		</div>

		<div className={!successPosted? "post_form":"hidden"}>
			<div className="market_trading pop_model_section">
				<div className="text_center model_title_section">
				<h3 className="text_center"> Add account wallet </h3>	
				</div>

				<div className="pop_model_section_blocks">
				<div className="input_block form_input_field">
					<label>Currency</label>	
					<Select value={selectedCurrency} 
							className="input_select" 
							onChange={changeDefaultCurreny}
							options={marketplaceState.currencyOptions} 
							isSearchable="true" />
				</div>
				</div> 

				<div className="pop_model_section_blocks">
				<div className="input_block form_input_field">
					
					<label>Wallet label</label>	
					<input className="column_100 input input_black_border nomargin_bottom" 
						type="text" 
						placeholder=""
						{...register("description", { required: true })} />
					{errors.description?.type === "required" && (
					<span className="input_error"><Info className="input_error_icon" /> Wallet label is required </span>
					)}	   
				</div>
				</div> 

			</div> 
			<div className="input_block_details">
				<div className="add_new_button column_100"> 
				{process.isPending && <button> <Loader2 className="processing_loading" /> </button>}
				{!process.isPending && <button onClick={handleSubmit(onSaving)}> Add wallet </button>}
				</div>
			</div>
	   </div>	

	  </div>



	);
};
export default AccountWallet;
