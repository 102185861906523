import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer } from "react";
import { useAuth } from "./AuthProvider";
import api from "../api";

const WalletsContext = createContext();
const WalletsDispatchContext = createContext();

export const WalletsProvider = ({ children }) => {
  const { authTokens, isLoggedIn } = useAuth();

  const [walletsState, walletsDispatch] = useReducer(walletsReducer, {
    wallets: [],
    mudaWallets: [],
    isLoadingWallets: false,
  });

  // Fetch wallets query
  const getWallets = useQuery({
    queryKey: ["wallets"],
    queryFn: () => {
      walletsDispatch({ type: "fetchingWallets" });
      return api.getWallets({ token: authTokens.IdToken });
    },
    enabled: false,
  });

  // Fetch Muda Wallets
  const getMudaWallet = async (payModeOption) => {
    if (!isLoggedIn) return;
    try {
      walletsDispatch({ type: "fetchingMudaWallets" });
      const { data: result, status } = await api.getMudaPaymentWallet({
                                                                        token:   authTokens.IdToken,
                                                                        options: payModeOption
                                                                    });
      
      if (status === 200 && result?.items) {
         const mudaWallets = result.items;
         walletsDispatch({ type: "fetchedMudaWallets", mudaWallets });
      }
    } catch (error) {
      console.error("[getMudaWallet] Error:", error.message);
    }
  };

  const getWallet = ({ currency }) => 
    walletsState.wallets.find((w) => w.currency === currency);

  const getFilteredWallets = ({ currency }) => 
    walletsState.wallets.filter((w) => w.currency === currency);

  const getAccountWallet = () => walletsState.wallets;

  const getUserAccountWallet = async () => {
    if (!isLoggedIn) return;
    (async () => {
      const { data: result, status } = await getWallets.refetch();
      if (status === "success" && result?.data?.data) {
        let wallets = result.data.data.map((datum) => datum.attributes);
        wallets     = wallets.sort((a, b) =>a.currency.toLowerCase().localeCompare(b.currency.toLowerCase()))
        walletsDispatch({ type: "fetchedWallets", wallets });
      }
    })();
  };

  useEffect(() => {

    getUserAccountWallet();
    // if (!isLoggedIn) return;
    // (async () => {
    //   const { data: result, status } = await getWallets.refetch();
    //   if (status === "success" && result?.data?.data) {
    //     let wallets = result.data.data.map((datum) => datum.attributes);
    //     wallets     = wallets.sort((a, b) =>a.currency.toLowerCase().localeCompare(b.currency.toLowerCase()))
    //     walletsDispatch({ type: "fetchedWallets", wallets });
    //   }
    // })();
  }, [isLoggedIn]);

  return (
    <WalletsContext.Provider
      value={{
        ...walletsState,
        getWallet,
        getFilteredWallets,
        getAccountWallet,
        getMudaWallet,
        getUserAccountWallet
      }}
    >
      <WalletsDispatchContext.Provider value={walletsDispatch}>
        {children}
      </WalletsDispatchContext.Provider>
    </WalletsContext.Provider>
  );
};

export const useWallets = () => useContext(WalletsContext);

export const useWalletsDispatch = () => useContext(WalletsDispatchContext);

// Wallets Reducer
const walletsReducer = (state, action) => {
  switch (action.type) {
    case "fetchedWallets":
      return { ...state, wallets: action.wallets, isLoadingWallets: false };
    case "fetchingWallets":
      return { ...state, isLoadingWallets: true };
    case "fetchedMudaWallets":
      return { ...state, mudaWallets: action.mudaWallets, isLoadingWallets: false };
    case "fetchingMudaWallets":
      return { ...state, isLoadingWallets: true };
    default:
      throw new Error(`[walletsReducer] Unknown action type: ${action.type}`);
  }
};
