import { ArrowUpRightIcon, ArrowDownLeftIcon } from "@heroicons/react/24/solid";
import { useRef } from "react";
import cn from "classnames";
import logo from "../assets/logo.svg";
import profileImage from "../assets/profile-pic.png";
import bitcoin_logo from "../assets/bitcoin_logo.svg";
import "./header.scss";
import Version from "../version/version";
import { useAuth } from "../providers/AuthProvider";
import ProfilePopOver from "./profilePopover/profilePopover";
import { useLocation, useNavigate } from "react-router-dom";
import { useRates } from "../providers/Rates/RatesProvider";
import _ from "lodash";
import { Loader2 } from "lucide-react";
import Big from "big.js";

const Header = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoadingRates, rates, getRate } = useRates();
  const topRates = [
    {
      logo: null,
      name: "UGX",
      price: {
        whole: "29,313",
        decimal: null
      },
      mvt: {
        amount: null,
        direction: null
      }
    },
    {
      logo: null,
      name: "KES",
      price: {
        whole: "29,313",
        decimal: null
      },
      mvt: {
        amount: null,
        direction: null
      }
    },
    {
      logo: null,
      name: "TZS",
      price: {
        whole: "29,313",
        decimal: null
      },
      mvt: {
        amount: null,
        direction: null
      }
    }
  ];

  const profileClick = useRef(null);
  const profilePopover = useRef(null);

  const positionProfilePopover = () => {
    const dp = profileClick.current;
    const window = profilePopover.current;

    window.style.top = `${dp.offsetTop + dp.offsetHeight + 5}px`;
    window.style.left = `${dp.offsetLeft - (300 / 2 - dp.offsetWidth / 2)}px`;
  };

  const gotoRates = () => {
    navigate(`/rates`);
  };

  const gotoLogin = () => {
    navigate(`/login`);
  };

  const showAllRatesBtn = () => {
    let show = false;

    if (isLoggedIn) {
      if (location.pathname != "/rates") {
        show = true;
      }
    }

    if (!isLoggedIn) {
      if (location.pathname != "/rates" && location.pathname != "/") {
        show = true;
      }
    }

    return show;
  };

  return (
    <div id="header_component">
      <div className={cn("header", { is_logged_in: isLoggedIn })}>
        {!isLoggedIn && (
          <div className="logo">
            <img src={logo} alt="" />
          </div>
        )}

        <div className="rates_container">
          <div className="rates">
            {(isLoadingRates || _.isEmpty(rates.rates)) && (
              <div className="rates_loader_container">
                Loading rates...
                <Loader2 className="rates_loader" />
              </div>
            )}

            {!isLoadingRates && !_.isEmpty(rates.rates) && (
              <>
                {topRates.map((rate, idx) => {
                  let sellRate = getRate({
                    rates,
                    from: "USD",
                    to: rate.name
                  });
                  sellRate = Big(sellRate).round(5).toString();
                  const locale = Intl.NumberFormat().resolvedOptions().locale;
                  const options = { minimumFractionDigits: 0, maximumFractionDigits: 10 };

                  const sellRateFormatted = `${sellRate.toLocaleString(locale, options)}`;

                  return (
                    <div className="rate" key={idx}>
                      {rate.logo && <img className="logo" src={rate.logo} alt="" />}
                      <span className="name">USD / {rate.name}</span>
                      <span className="price">
                        {sellRateFormatted}
                        {rate.price.decimal && <span className="decimal">.{rate.price.decimal}</span>}
                      </span>

                      {rate.mvt.direction == "up" && (
                        <>
                          <span className="mvt_icon">
                            <ArrowUpRightIcon className="icon up"></ArrowUpRightIcon>
                          </span>
                          <span className="mvt_amt">+{rate.mvt.amount}%</span>
                        </>
                      )}

                      {rate.mvt.direction == "down" && (
                        <>
                          <span className="mvt_icon">
                            <ArrowDownLeftIcon className="icon down"></ArrowDownLeftIcon>
                          </span>
                          <span className="mvt_amt">-{rate.mvt.amount}%</span>
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>

          {showAllRatesBtn() && (
            <div className="control all_rates" onClick={gotoRates}>
              All Rates
            </div>
          )}

          {isLoggedIn && (
            <>
              <button
                className="profile"
                ref={profileClick}
                onClick={positionProfilePopover}
                id="profile_click_control"
                popovertarget="profile_avatar_popover"
                popovertargetaction="show">
                <img src={profileImage} alt="" />
              </button>
              <div ref={profilePopover} id="profile_avatar_popover" popover="auto">
                <ProfilePopOver />
              </div>
            </>
          )}
        </div>

        {!isLoggedIn && location.pathname != "/login" && (
          <div className="cta" onClick={gotoLogin}>
            Log in
          </div>
        )}

        {/* <Popover className="relative">
          <Popover.Button>Solutions</Popover.Button>

          <Popover.Panel className="absolute z-10">
            <div className="grid grid-cols-2">
              <a href="/analytics">Analytics</a>
              <a href="/engagement">Engagement</a>
              <a href="/security">Security</a>
              <a href="/integrations">Integrations</a>
            </div>

            <img
              src="/solutions.jpg"
              alt=""
            />
          </Popover.Panel>
        </Popover> */}
      </div>
      <Version />
    </div>
  );
};

export default Header;
