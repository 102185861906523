import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer } from "react";
import { useAuth } from "./AuthProvider";
import api from "../api";
import { getProcessedOrder } from "../common/getProcessedOrder";
import Big from "big.js";
import moment from "moment";
import allRequests from "../workspace/myactivity/transactions/allRequests/allRequests";

const RequestsContext = createContext();
const RequestsDispatchContext = createContext();

export const REQUESTS_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const REQUEST_STATES = {
	CREATED: "CREATED",
	ACCEPTED: "ACCEPTED",
	FULFILLED: "FULFILLED",
	PROCESSING: "PROCESSING"
};

export const RequestsProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getSentRequests = useQuery({
		queryKey: ["SentRequests"],
		queryFn: () => {
			return api.getSentRequests({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const getReceivedRequests = useQuery({
		queryKey: ["ReceivedRequests"],
		queryFn: () => {
			return api.getReceivedRequests({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const getAllRequests = useQuery({
		queryKey: ["AllRequests"],
		queryFn: () => {
			return api.getAllRequests({ token: authTokens.IdToken });
		},
		enabled: false,
	});
	const getCompleteRequests = useQuery({
		queryKey: ["CompleteRequests"],
		queryFn: () => {
			return api.getFulfilledRequests({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const acceptRequest = useMutation({
		mutationFn: ({ request, token }) => {
			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isAcceptingRequest", value: true });
			return api.acceptRequest({ request, token });
		},
		onError: (error, variables, context) => {
			console.log(error);
			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isAcceptingRequest", value: false });
		},
		onSuccess: async () => {
			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isAcceptingRequest", value: false });
			await fetchReceivedRequests();
		
		},
	});


	const rejectRequest = useMutation({
		mutationFn: ({ request, token }) => {
			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isRejectingRequest", value: true });
			return api.rejectRequest({ request, token });
		},
		onError: (error, variables, context) => {
			console.log(error);
			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isRejectingRequest", value: false });
		},
		onSuccess: async () => {
			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isRejectingRequest", value: false });
			await fetchReceivedRequests();
		},
	});
	const fetchReceivedRequests = async () => {
		requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isFetchingReceivedRequests", value: true });
		const receivedRequestsRefetchResult = await getReceivedRequests.refetch();
		requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isFetchingReceivedRequests", value: false });
		if (receivedRequestsRefetchResult.status == "success") {


			let receivedRequests = receivedRequestsRefetchResult.data.data.data;
			const formatAmounts = (amount) => {
				const locale = Intl.NumberFormat().resolvedOptions().locale;
				let formatedAmount = parseFloat(amount);
				formatedAmount = `${formatedAmount.toLocaleString(locale)}`;
				return formatedAmount;
			};

			receivedRequests = receivedRequests.map((_) => {
				const request = _.attributes;
				const processedOrder = getProcessedOrder({ order: request.order });
				request.processedOrder = processedOrder;

				const desiredPurchaseAmountFormatted = formatAmounts(request.desiredPurchaseAmount);
				request.desiredPurchaseAmountFormatted = desiredPurchaseAmountFormatted;

				const desiredPurchaseAmountConverted = Big(request.desiredPurchaseAmount).div(Big(request.order.volumeTypeAmount)).toString();
				request.desiredPurchaseAmountConverted = desiredPurchaseAmountConverted;

				const desiredPurchaseAmountConvertedFormatted = formatAmounts(desiredPurchaseAmountConverted);
				request.desiredPurchaseAmountConvertedFormatted = desiredPurchaseAmountConvertedFormatted;

				const createdAtFormatted = moment(request.createdAt).format("DD/MM/YYYY - h:mm A");
				request.createdAtFormatted = createdAtFormatted;

				let stateFormatted;
				let stateClass;
				switch (request.requestState) {
					case "CREATED":
						stateFormatted = "Open";
						stateClass = "request_state_open";
						break;
					default:
						stateFormatted = `n/a [${request.requestState}]`;
						break;
				}
				request.stateFormatted = stateFormatted;
				request.stateClass = stateClass;

				return request;
			});

			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "receivedRequests", value: receivedRequests });
		}
	};

	const fetchCompleteRequests = async () => {
		requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isFetchingCompleteRequests", value: true });
		const completeRequestsRefetchResult = await getCompleteRequests.refetch();
		requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isFetchingCompleteRequests", value: false });
	
		if (completeRequestsRefetchResult.status === "success") {

			let completeRequests = completeRequestsRefetchResult.data.data.data;
			//completeRequests = completeRequests.sort((a, b) => new Date(b.date) - new Date(a.date));
	
			const formatAmounts = (amount) => {
				const locale = Intl.NumberFormat().resolvedOptions().locale;
				let formattedAmount = parseFloat(amount);
				formattedAmount = `${formattedAmount.toLocaleString(locale)}`;
				return formattedAmount;
			};
	
			completeRequests = completeRequests.map((_) => {
				const request = _.attributes;
	
				const processedOrder = getProcessedOrder({ order: request.order });
				request.processedOrder = processedOrder;
	
				const desiredPurchaseAmountFormatted = formatAmounts(request.desiredPurchaseAmount);
				request.desiredPurchaseAmountFormatted = desiredPurchaseAmountFormatted;
	
				const desiredPurchaseAmountConverted = Big(request.desiredPurchaseAmount).div(Big(request.order.volumeTypeAmount)).toString();
				request.desiredPurchaseAmountConverted = desiredPurchaseAmountConverted;
	
				const desiredPurchaseAmountConvertedFormatted = formatAmounts(desiredPurchaseAmountConverted);
				request.desiredPurchaseAmountConvertedFormatted = desiredPurchaseAmountConvertedFormatted;
	
				const createdAtFormatted = moment(request.createdAt).format("DD/MM/YYYY - h:mm A");
				request.createdAtFormatted = createdAtFormatted;
	
				let stateFormatted;
				let stateClass;
				switch (request.requestState) {
					case "FULFILLED":
						stateFormatted = "Completed";
						stateClass = "request_state_completed";
						break;
					default:
						stateFormatted = `n/a [${request.requestState}]`;
						break;
				}
				request.stateFormatted = stateFormatted;
				request.stateClass = stateClass;
	
				
	
				return request;
			});
	
			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "completeRequests", value: completeRequests });
		}
	};
	

	const fetchAllRequests = async () => {
		requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isFetchingAllRequests", value: true });
		const allRequestsRefetchResult = await getAllRequests.refetch();
		requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isFetchingAllRequests", value: false });
		if (allRequestsRefetchResult.status == "success") {
			let allRequests = allRequestsRefetchResult.data.data.data;
			//allRequests = allRequests.sort((a, b) => new Date(b.date) - new Date(a.date));
	

			const formatAmounts = (amount) => {
				const locale = Intl.NumberFormat().resolvedOptions().locale;
				let formatedAmount = parseFloat(amount);
				formatedAmount = `${formatedAmount.toLocaleString(locale)}`;
				return formatedAmount;
			};

			allRequests = allRequests.map((_) => {
				const request = _.attributes;

				const processedOrder = getProcessedOrder({ order: request.order });
				request.processedOrder = processedOrder;

				const desiredPurchaseAmountFormatted = formatAmounts(request.desiredPurchaseAmount);
				request.desiredPurchaseAmountFormatted = desiredPurchaseAmountFormatted;

				const desiredPurchaseAmountConverted = Big(request.desiredPurchaseAmount).div(Big(request.order.volumeTypeAmount)).toString();
				request.desiredPurchaseAmountConverted = desiredPurchaseAmountConverted;

				const desiredPurchaseAmountConvertedFormatted = formatAmounts(desiredPurchaseAmountConverted);
				request.desiredPurchaseAmountConvertedFormatted = desiredPurchaseAmountConvertedFormatted;

				const createdAtFormatted = moment(request.createdAt).format("DD/MM/YYYY - h:mm A");
				request.createdAtFormatted = createdAtFormatted;

				let stateFormatted;
				let stateClass;
				switch (request.requestState) {
					case "CREATED":
						stateFormatted = "Open";
						stateClass = "request_state_open";
						break;
					case "FULFILLED":
						stateFormatted = "Completed";
						stateClass = "request_state_completed";
						break;
					case "ACCEPTED":
							stateFormatted = "Accepted";
							stateClass = "request_state_accepted";
							break;
					default:
						stateFormatted = `n/a [${request.requestState}]`;
						break;
				}
				request.stateFormatted = stateFormatted;
				request.stateClass = stateClass;

				

				return request;
			});

			requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "allRequests", value: allRequests });
		}
	};

	const [requestsState, requestsDispatch] = useReducer(RequestsReducer, {
		Requests: {},
		sentRequests: [],
		receivedRequests: [],
		allRequests:[],
		getAllRequests,
		fetchAllRequests,
		completeRequests: [],
		getCompleteRequests,
		getSentRequests,
		isFetchingSentRequests: false,
		isFetchingAllRequests: false,
		isFetchingCompleteRequests: false, 
		fetchReceivedRequests,
		getReceivedRequests,
		isAcceptingRequest: false,
		isRejectingRequest: false,
		acceptRequest: ({ request, token }) => acceptRequest.mutate({ request, token }),
		rejectRequest: ({ request, token }) => rejectRequest.mutate({ request, token }),
		fetchCompleteRequests, // Add function to fetch complete requests
	});

	return (
		<RequestsContext.Provider value={requestsState}>
			<RequestsDispatchContext.Provider value={requestsDispatch}>{children}</RequestsDispatchContext.Provider>
		</RequestsContext.Provider>
	);
};

export const useRequests = () => {
	return useContext(RequestsContext);
};

export const useRequestsDispatch = () => {
	return useContext(RequestsDispatchContext);
};

const RequestsReducer = (requestsState, action) => {
	switch (action.type) {
		case "fetchedRequests":
			return { ...requestsState, Requests: action.Requests };
		case REQUESTS_ACTIONS.updateState: {
			const newRequestsState = { ...requestsState };
			newRequestsState[action.key] = action.value;
			return newRequestsState;
		}
		case REQUESTS_ACTIONS.updateStates: {
			const newRequestsState = { ...requestsState, ...action.states };
			return newRequestsState;
		}
		default: {
			throw Error("[RequestsReducer] Unknown action: " + action.type);
		}
	}
};
