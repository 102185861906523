import { useEffect, useRef } from "react";
import cn from "classnames";
import _ from "lodash";
import Login from "../login/login";
import { useAuth } from "../providers/AuthProvider";
import logo from "../assets/logo.svg";
import tick_icon from "../assets/tick_icon.svg";
import dashboard_icon from "../assets/dashboard_icon.svg";
import wallets_icon from "../assets/wallets_iconx.svg";
import myactivity_icon from "../assets/myactivity_icon.svg";
import marketplace_icon from "../assets/marketplace_icon.svg";
import dealrooms_icon from "../assets/dealrooms_icon.svg";
import reports_icon from "../assets/reports_icon.svg";
import currency_exchange_icon from "../assets/currency_exchange_icon.svg";
import logout_icon from "../assets/logout_icon.svg";
import settings_icon from "../assets/dashboard_icon.svg";
import Header from "../header/header";
import "./workspace.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useProfile } from "../providers/ProfileProvider";
import { useFeatureFlags } from "../providers/FeatureFlags/FeatureFlagsProvider";
import Rates from "./rates/rates";

const Workspace = () => {
  const { isLoggedIn, setIsLoggedIn, setAuthTokens } = useAuth();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const logoutDialogRef = useRef(null);
  const welcomeDialogRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { profile } = useProfile();

  useEffect(() => {
    if (!_.isEmpty(profile) && profile.onboarded != "true" && welcomeDialogRef.current && location.pathname != "/kyc") {
      welcomeDialogRef.current.showModal();
    }
  }, [profile, location]);

  const links = [
    {
      icon: dashboard_icon,
      name: "Dashboard",
      url: "/"
    },
    {
      icon: wallets_icon,
      name: "Wallets",
      url: "/wallets"
    },
    {
      icon: currency_exchange_icon,
      name: "Rates",
      url: "/rates"
    },
    {
      icon: myactivity_icon,
      name: "My Activity",
      class: "myactivity_link",
      url: "/myactivity"
    },
    {
      icon: marketplace_icon,
      name: "Marketplace",
      class: "marketplace_link",
      url: "/marketplace"
    },
    {
      icon: dealrooms_icon,
      name: "Dealrooms",
      featureName: "dealrooms",
      url: "/dealrooms"
    },
    {
      icon: reports_icon,
      name: "Reports",
      url: "/reports"
    },
    {
      icon: settings_icon,
      name: "Settings",
      url: "/settings"
    },
    {
      name: "createCompanyAccount",
      class: "createCompanyAccount_link",
      url: "/create_company"
    },
    {
      name: "verifyCompanyAccount",
      class: "verifyCompanyAccount_link",
      url: "/verify_company_account"
    }
  ];

  const startLogout = () => {
    logoutDialogRef.current.showModal();
  };

  const cancelLogout = () => {
    logoutDialogRef.current.close();
  };

  const confirmLogout = () => {
    //Logout (should be in auth provider)
    localStorage.removeItem("authTokens");
    setAuthTokens({});
    setIsLoggedIn(false);
    navigate("/login");
  };

  const onStartVerification = () => {
    welcomeDialogRef.current.close();
    navigate("/kyc");
  };

  const onSkipVerification = () => {
    welcomeDialogRef.current.close();
  };

  const WelcomeIcon = () => {
    return (
      <div className="welcome_icon_container">
        <div className="welcome_icon_outer_ring">
          <div className="welcome_icon_inner_ring">
            <img src={tick_icon} alt="" />
          </div>
        </div>
      </div>
    );
  };

  const linkActive = (link) => {
    if (link.name == "Dashboard") return location.pathname == link.url;
    return location.pathname.includes(link.url);
  };

  return (
    <>
      {!isLoggedIn && location.pathname != "/rates" && location.pathname != "/" && <Login />}

      {!isLoggedIn && location.pathname == "/" && <Rates />}

      {!isLoggedIn && location.pathname == "/rates" && <Rates />}

      {isLoggedIn && (
        <div className="workspace">
          <div className="sidebar">
            <div className="logo">
              <img src={logo} alt="" />
            </div>

            <div className="links">
              {links.map((link, idx) => {
                const linkHtml = (
                  <Link className={cn("link", link.class, { active: linkActive(link) })} key={link.url} to={link.url}>
                    <div className="highlighter">
                      <img src={link.icon} alt="" className="icon" />
                      <span className="label">{link.name}</span>
                    </div>
                  </Link>
                );

                //Only check feature flags if link has featureName
                if (link.featureName) {
                  if (isFeatureFlagEnabled(link.featureName)) {
                    return linkHtml;
                  } else {
                    //This is just a placeholder for hidden content
                    return <a key={link.url} style={{ display: "none" }}></a>;
                  }
                } else {
                  return linkHtml;
                }
              })}
            </div>

            <div className="logout_container" onClick={startLogout}>
              <div className="logout_icon">
                <img src={logout_icon} alt="" />
              </div>
              <div className="logout_label">Logout</div>
            </div>
            <dialog className="logout_dialog_container" ref={logoutDialogRef}>
              <div className="logout_dialog_heading">Are you sure you want to log out?</div>
              <div className="logout_dialog_controls">
                <div onClick={confirmLogout} className="logout_dialog_control logout_dialog_confirm">
                  Yes
                </div>
                <div onClick={cancelLogout} className="logout_dialog_control logout_dialog_cancel">
                  No
                </div>
              </div>
            </dialog>
          </div>

          <div className="main_content">
            <Header />
            <Outlet />
            <dialog className="welcome_dialog_container" ref={welcomeDialogRef}>
              <WelcomeIcon />
              <div className="welcome_heading">Welcome to Muda</div>
              <div className="welcome_sub_heading">To start using all Muda services, please complete the verification process</div>
              <div className="welcome_controls">
                <div onClick={onStartVerification} className="welcome_control start_verification">
                  Start verification
                </div>
                <div className="welcome_control skip" onClick={onSkipVerification}>
                  Skip now
                </div>
              </div>
            </dialog>
          </div>
        </div>
      )}
    </>
  );
};

export default Workspace;
