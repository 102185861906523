import { createContext, useContext, useEffect, useReducer } from "react";
import { useAuth } from "../../../providers/AuthProvider";
import { useQuery } from "@tanstack/react-query";
import api from "../../../api";

const MarketplaceContext = createContext();
const MarketplaceDispatchContext = createContext();
export const MarketplaceProvider = ({ children }) => {
	const { authTokens, isLoggedIn } = useAuth();
	const getMarketplaceOrders = useQuery({
		queryFn: () => {
			return api.getMarketplaceOrders({ token: authTokens.IdToken });
		},
		queryKey: ["marketplaceorders"],
		enabled: false,
	});

	const reloadMarketPlaceOrders = async () => {
		marketplaceDispatch({ type: "updateState", key: "isFetchingMarketplaceOrders", value: true });
		const marketplaceOrdersRefetchResult = await getMarketplaceOrders.refetch();
		marketplaceDispatch({ type: "updateState", key: "isFetchingMarketplaceOrders", value: false });
		const marketplaceOrders = marketplaceOrdersRefetchResult.data.data.data.map((datum) => datum.attributes);
		marketplaceDispatch({ type: "updateState", key: "marketplaceOrders", value: marketplaceOrders });
	};

	const emptyMarketplaceState = {
		isFetchingMarketplaceOrders: false,
		getMarketplaceOrders,
		reloadMarketPlaceOrders,
		marketplaceOrders: [],
	};

	const [marketplaceState, marketplaceDispatch] = useReducer(marketplaceReducer, emptyMarketplaceState);

	// Initilaize marketplace state
	useEffect(() => {
		if (!isLoggedIn) {
			marketplaceDispatch({ type: "clearState" });
		}

		if (isLoggedIn) {
			// load market place orders
			(async () => {
				marketplaceDispatch({ type: "updateState", key: "isFetchingMarketplaceOrders", value: true });
				const marketplaceOrdersRefetchResult = await getMarketplaceOrders.refetch();
				marketplaceDispatch({ type: "updateState", key: "isFetchingMarketplaceOrders", value: false });
				let marketplaceOrders = marketplaceOrdersRefetchResult.data.data.data.map((datum) => datum.attributes);
                marketplaceOrders     = marketplaceOrders.sort((a, b) => b.sellCurrencyToUsdtRate - a.sellCurrencyToUsdtRate);
				marketplaceDispatch({ type: "updateState", key: "marketplaceOrders", value: marketplaceOrders });
			})();
		}
	}, [isLoggedIn]);

	return (
		<MarketplaceContext.Provider value={marketplaceState}>
			<MarketplaceDispatchContext.Provider value={marketplaceDispatch}>{children}</MarketplaceDispatchContext.Provider>
		</MarketplaceContext.Provider>
	);
};

export const useMarketplace = () => {
	return useContext(MarketplaceContext);
};

export const useMarketplaceDispatch = () => {
	return useContext(MarketplaceDispatchContext);
};

const marketplaceReducer = (marketplaceState, action) => {
	switch (action.type) {
		case "updateState":
			const newMarketplaceState = { ...marketplaceState };
			newMarketplaceState[action.key] = action.value;
			return newMarketplaceState;
		case "clearState":
			const emptyMarketplaceState = {
				isFetchingMarketplaceOrders: false,
				marketplaceOrders: [],
			};
			return { ...marketplaceState, ...emptyMarketplaceState };
		default: {
			throw Error("[marketplaceReducer] Unknown action: " + action.type);
		}
	}
};
