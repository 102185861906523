import { MoreVertical } from "lucide-react";
import "./openorders.scss";
import { useOtctrades } from "../../../providers/OtctradesProvider";
import { formatAmount } from "../../../common/formatAmount";
import { formatDate } from "../../../common/formatDate";


const Openorders = () => {
	const { otctrades, isLoadingOtctrades } = useOtctrades();
	return (
		<div className="dashboard_openorders_component">
			{!isLoadingOtctrades && (
				<table className="open_orders_table">
					<thead>
						<tr>
							<th className="flexing_content flex_container">
							   <span>Pair</span>
							</th>
							<th> Amount </th>
							<th> Actual Price </th>
							<th> Date </th>
							<th> Completion %</th>
							<th> Details </th>
						</tr>
					</thead>
					<tbody>
						{otctrades.map((otctrade) => (
							<tr key={otctrade.id}>
								<td>
									{otctrade?.sellCurrency}-{otctrade?.buyCurrency}
								</td>
								<td>{formatAmount(otctrade?.sellAmount)} {otctrade?.sellCurrency}</td>
								<td>{formatAmount(otctrade?.rawVolumeInput?.volumeTypeAmount)} {otctrade?.balanceCurrency}</td>
								<td>{formatDate(otctrade?.createdAt)}</td>
								<td className="action_td_tabs table_width_20">
							    	{/* <progress id="file" value="32" max="100">
										32%
									</progress> */}
									<div className="completion_date_background">
									  <div className="completion_date_body" style={{'width':'10%'}}>
										<p>10%</p>
									  </div>  
									</div>
								</td>
								<td className="action_td_tabs table_width_10">
								   <MoreVertical />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default Openorders;
