// import { useState } from "react";
import "./markettrade.scss";
import { useRates } from "../../../../providers/Rates/RatesProvider";
import { Loader2 } from "lucide-react";
import _ from "lodash";
import { useTransact, useTransactDispatch } from "../../providers/TransactProvider";
import { useState } from "react";
import { useWallets } from "../../../../providers/WalletsProvider";
import Big from "big.js";

const Markettrade = () => {
	const [errors, setErrors] = useState({});
	const { marketTradeState } = useTransact();
	const transactDispatch = useTransactDispatch();
	const { isLoadingRates, convert, rates, currencies, walletCurrencies } = useRates();
	const { getWallet } = useWallets();
	const wallet = getWallet({ currency: marketTradeState.sell.currency });

	const validateTradeInput = () => {
		let temp = {};
		temp.buy_amount = marketTradeState.buy.amount ? "" : "Buy amount is required";
		temp.sell_amount = marketTradeState.sell.amount ? "" : "Sell amount is required";
		const sellAmount = Big(marketTradeState.sell.amount || 0);
		const walletBalance = wallet ? Big(wallet.balance) : Big(0);
		if (sellAmount.gt(walletBalance)) {
			temp.sell_amount = "Sell amount should not be higher than wallet balance";
		}
		setErrors({ ...temp });
		return Object.values(temp).every((x) => x === "");
	};

	return (
		<div id="markettrade_component">
			{isLoadingRates && (
				<div className="rates_loader_container">
					Loading rates...
					<Loader2 className="rates_loader" />
				</div>
			)}

			{!isLoadingRates && (
				<>
					<div className="currency_inputs">
						<div className="currency_input_box buy_currency">
							<div className="currency_trade_label">Buy</div>
							<div className="currency_input currency">
								<select
									onChange={(evt) => {
										const buyCurrency = evt.target.value;
										const newBuy = { ...marketTradeState.buy, currency: buyCurrency };
										//convert sell
										let newSellAmount = "";
										if (!_.isEmpty(marketTradeState.buy.amount)) {
											newSellAmount = convert({
												rates,
												from: buyCurrency,
												to: marketTradeState.sell.currency,
												amount: marketTradeState.buy.amount,
											});
										}
										const newSell = { ...marketTradeState.sell, amount: newSellAmount };
										const newMarketTradeState = { ...marketTradeState, buy: newBuy, sell: newSell };
										transactDispatch({
											type: "setMarketTradeState",
											marketTradeState: newMarketTradeState,
										});
									}}
									defaultValue={marketTradeState.buy.currency}>
									{walletCurrencies.map((c, i) => (
										<option key={i} value={c.code}>
											{c.code}
										</option>
									))}
								</select>
							</div>
							<div className="currency_input amount">
								<input
									type="number"
									min="0"
									value={marketTradeState.buy.amount}
									onChange={(evt) => {
										setErrors({ ...errors, buy_amount: "", sell_amount: "" });
										const buyAmount = evt.target.value;
										const newBuy = { ...marketTradeState.buy, amount: buyAmount };
										//convert sell
										let newSellAmount = "";
										if (!_.isEmpty(buyAmount)) {
											newSellAmount = convert({
												rates,
												from: marketTradeState.buy.currency,
												to: marketTradeState.sell.currency,
												amount: buyAmount,
											});
										}

										const newSell = { ...marketTradeState.sell, amount: newSellAmount };
										const newMarketTradeState = { ...marketTradeState, buy: newBuy, sell: newSell };
										transactDispatch({
											type: "setMarketTradeState",
											marketTradeState: newMarketTradeState,
										});
									}}
								/>
								{errors?.buy_amount && <div className="error-message">{errors.buy_amount}</div>}
							</div>
						</div>
						<div className="currency_input_box sell_currency">
							<div className="currency_trade_label">Sell</div>
							<div className="currency_input currency">
								<select
									name=""
									id=""
									onChange={(evt) => {
										const sellCurrency = evt.target.value;
										const newSell = { ...marketTradeState.sell, currency: sellCurrency };
										//convert buy
										let newBuyAmount = "";
										if (!_.isEmpty(marketTradeState.sell.amount)) {
											newBuyAmount = convert({
												rates,
												from: sellCurrency,
												to: marketTradeState.buy.currency,
												amount: marketTradeState.sell.amount,
											});
										}
										const newBuy = { ...marketTradeState.buy, amount: newBuyAmount };
										const newMarketTradeState = { ...marketTradeState, sell: newSell, buy: newBuy };
										transactDispatch({
											type: "setMarketTradeState",
											marketTradeState: newMarketTradeState,
										});
									}}
									defaultValue={marketTradeState.sell.currency}>
									{walletCurrencies.map((c, i) => (
										<option key={i} value={c.code}>
											{c.code}
										</option>
									))}
								</select>
							</div>
							<div className="currency_input amount sell_amount">
								<input
									type="number"
									min="0"
									value={marketTradeState.sell.amount && Big(marketTradeState.sell.amount).round(4).toString()}
									onChange={(evt) => {
										setErrors({ ...errors, sell_amount: "", buy_amount: "" });
										const sellAmount = evt.target.value;
										const newSell = { ...marketTradeState.sell, amount: sellAmount };
										//convert buy
										let newBuyAmount = "";
										if (!_.isEmpty(sellAmount)) {
											newBuyAmount = convert({
												rates,
												from: marketTradeState.sell.currency,
												to: marketTradeState.buy.currency,
												amount: sellAmount,
											});
										}
										const newBuy = { ...marketTradeState.buy, amount: newBuyAmount };
										const newMarketTradeState = { ...marketTradeState, sell: newSell, buy: newBuy };
										transactDispatch({
											type: "setMarketTradeState",
											marketTradeState: newMarketTradeState,
										});
									}}
								/>
								{errors?.sell_amount && <div className="error-message">{errors.sell_amount}</div>}
							</div>
						</div>
					</div>
					<div className="summary">
						<div className="balance">
							<div className="balance_label">Total balance:</div>
							<div className="balance_amount">{wallet ? `${Big(wallet.balance).round(4).toString()} ${wallet.currency}` : 0}</div>
						</div>
						<div
							onClick={() => {
								if (validateTradeInput()) {
									transactDispatch({ type: "confirmMarketTrade" });
								}
							}}
							className="place_order">
							Place order
						</div>
					</div>
				</>
			)}
		</div>
	);
};
export default Markettrade;
