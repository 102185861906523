import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.scss";
import Transact     from "../transact/transact";
import {dynamicImage} from "../../common/dynamicImage";
import { formatAmount } from "../../common/formatAmount";

import dashboard_trade_icon from "../../assets/dashboard_trade_icon.svg";
import reload_page_icon from "../../assets/refresh.svg";
import dashboard_deposit_icon from "../../assets/dashboard_deposit_icon.svg";
import dashboard_withdraw_icon from "../../assets/dashboard_withdraw_icon.svg";
import tether_dark_icon        from "../../assets/dashboard/usdt_dark.svg";
import { useWallets }          from "../../providers/WalletsProvider";
import { useRates }            from "../../providers/Rates/RatesProvider";
import { useTransactDispatch } from "../transact/providers/TransactProvider";
import { useFeatureFlags }     from "../../providers/FeatureFlags/FeatureFlagsProvider";
import PopModel                from "../../components/models/walletsModel";
import WalletBalance           from "../../components/wallets/walletBalance";
import SingleWalletBalance     from "../../components/wallets/singleWalletBalance";

import Openorders from "./openorders/openorders";
import Requests   from "./requests/requests";
import Big from "big.js";

const Wallets = () => {
	const { isFeatureFlagEnabled }    = useFeatureFlags();
	const [pageReload, setPageReload] = useState(false);
	const [dashboardState, setDashboardState]             = useState({
														              viewOpenOrders: true,
														              viewRequests: false,
													                });
	const transactDispatch                                = useTransactDispatch();
	const { convert, rates, currencies, getRate }         = useRates();
	const { wallets, getAccountWallet, isLoadingWallets, getUserAccountWallet } = useWallets();
	const [ zeroBalance, setShowCurrencyWithZeroBalance ] = useState(false);
	const [accountTotalBalance,  setAccountTotalBalance] = useState(0); 

	const [ marketplaceState, setMarketplaceState]        = useState({
																buyCurrencyFilter: [],
																sellCurrencyFilter: [],
																walletsOptions: [
																	 {label: 'All', value: 'all'},
																	 {label: 'USDT', value: 'usdt'}
																	],
																defaultAccountWallets: [],
																currencyOptions: [],
															});
    
	const [modelStatus, setModelStatus] = useState(false); 
	const [dataModelContent, setModelDataContent] = useState({}); 
	const [dataResponse, setDataResponse] = useState({}); 
	const [selectedTab, setSelectedTab] = useState("open_orders"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const navigate = useNavigate();

	// 
	const initCollection = async () => {
		try{	
		  const currencyOptions =   currencies.map((currency) => ({
															  value: currency.code,
															  label: currency.code,
														  }));														  
		  const walletCollection = await getAccountWallet();
		  let   walletCollectionArr  = walletCollection; 
		  setMarketplaceState((prevState) => ({
												  ...prevState,
												  defaultAccountWallets: walletCollectionArr,
												  currencyOptions: currencyOptions
											 }));
		}catch(e){}
	}

	useEffect(() => {
	   initCollection();	
	}, [])

	const reloadPage = async (options) => {

		setPageReload(true);
		await getUserAccountWallet();
        await new Promise((r) => setTimeout(r, 600));
        initCollection();  
		setPageReload(false);
	}

	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.order_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 

	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){

			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	const getWalletPrice = (wallet) => {
		let amountStr = "-1";
		try {
			const amount = convert({
				rates,
				from: wallet.currency,
				to: "USD",
				amount: wallet.balance,
			});
			amountStr = `USD ${Big(amount).round(4).toString()}`;
		} catch (err) {}
		return amountStr;
	};

	const getWalletRate = (wallet) => {
		let rateStr = "-1";
		try {
			let rate = getRate({
				rates,
				from: wallet.currency,
				to: "USD",
			});
			rateStr = `${Big(rate).round(4).toString()} USD / ${wallet.currency}`;

			if (Big(rate).lt(1)) {
				rate = getRate({
					rates,
					from: "USD",
					to: wallet.currency,
				});
				rateStr = `${Big(rate).round(4).toString()} ${wallet.currency} / USD`;
			}
		} catch (err) {}
		return rateStr;
	};



	const handleChange = (checked) => {
      try{	
		setShowCurrencyWithZeroBalance(checked);
	  } catch (err) {
		console.log(err);
	  }	
	}

	
	/**  start the trading withdraw side model */ 
	const showModel = (options) => {
	  try{
		if(options.operation_type !== ""){
			setModelDataContent(options)
			setModelStatus(true);
		 }
	  }	catch(e){}
	}
	
	// close model popup
	const modelStatusTrack = (e) => {
      try{   
		if(e?.operation_type !== undefined && e?.operation_type !== "" ){
					
		  setModelStatus(false);
		  setModelDataContent(e);
		  setModelStatus(true);
		} else {
            
		  setModelStatus(false);
		}
	  }	catch(e){}	
	}

	// toggle depost model popup option
 	const modelToggleMethodOption = (e) => {
		try{   
		   setModelDataContent(e)
		}	catch(e){}	
	}


	return (
	  <>
		
		<div id="transact_popover" popover="auto">
			<Transact />
		</div>

		<div className="wallet_page scroll_right flexing_content flex_container">
			<PopModel openModelFunc={(e) => modelStatusTrack(e)} openModel={modelStatus} modelContent={dataModelContent} modeCloseResponse={dataResponse} toggleOperationMethodType={(e) => modelToggleMethodOption(e)}></PopModel> 
			<div className="section_main_header">
			  <div className="left_section text_left">
				<span>Dashboard</span>
			  </div>
			</div>

			

			<div className="wallets_and_controls">
				<div className="lhs">
				    <div className="wallets_header">Wallets</div>
					<WalletBalance wallets={wallets} />
				</div>
				<div className="rhs">
					<div className="controls">
					    <button
						  onClick={() => {
								reloadPage({ type: "reload" });
						  }}
						  className="control reload_btn">
						  <div className="control_icon">
							  <img src={reload_page_icon} alt="reload icon" className={pageReload? "roatating":""} />
						  </div>
						</button>

						<button
							onClick={() => {
								transactDispatch({ type: "transact" });
							}}
							className="control trade"
							popovertarget="transact_popover"
							popovertargetaction="show">
							<div className="control_icon">
								<img src={dashboard_trade_icon} alt="trade icon" />
							</div>
							<div className="control_title">TRADE</div>
						</button>
						
						<button className="control deposit" onClick={() => showModel({operation_type: 'crediting', 
										operation_method_type: 'deposit' })}>
							<div className="control_icon">
							  <img src={dashboard_deposit_icon} alt="deposit icon" />
							</div>
						  <div className="control_title">DEPOSIT</div>
						</button>
						<button className="control withdraw" 
									onClick={() => showModel({ operation_type: 'crediting', 
																operation_method_type: 'withdraw'})}>
								<div className="control_icon">
									<img src={dashboard_withdraw_icon} alt="withdraw icon" />
								</div>
								<div className="control_title">WITHDRAW</div>
						</button>
					</div>

					<div className="cards">
						{ wallets.slice(0, 3).map((wallet, walletKey) => (
						  <div className={(walletKey === 0)? "card tether":"card"}>
							<div className="info">
								<div className="heading">{wallet.description}</div>
								<div className="sub_heading">
								   {`${wallet.balance} ${wallet.currency}`}	
								</div>
								<div className="sub_sub_heading clearfix ">
									<SingleWalletBalance wallet={wallet} />
									<div className="icon">
									  <img src={dynamicImage({imageName: wallet.currency})} alt={wallet.currency} />
									</div>
								</div>
							</div>
							
						  </div>
					    ))}
                        
						{(wallets.slice(0, 3).length > 0)?
							<div className="card_control card_control_pointer" onClick={() => {
								// showModel({operation_type: 'account_wallet'})
							    navigate('/wallets') }}>
								<p>View all wallets</p>
							</div>
						 : ''}
					</div>
				</div>
			</div>

			<div className="text_left column_100 flexing_content flex_container dash_transactions_wallets">
			    
				<div className="payment_methods_tabs_header_container column_100 flexing_content flex_container">
				  <div className="payment_methods_tabs_header">
					<div role="my_accounts" className={(selectedTab === 'open_orders')? "payment_methods_tabs_header_tab_main active_tab_bg":"payment_methods_tabs_header_tab_main"} onClick={() => handleToggleTabs({order_type: 'open_orders'})}>
						<div className={(selectedTab === 'open_orders')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
							<span className="payment_methods_tabs_header_tab_text">Open orders</span>
						</div>
					</div>
					<div role="3rd_party_account" className={(selectedTab === 'requests')? "payment_methods_tabs_header_tab_main drag_left_20 active_tab_bg":"payment_methods_tabs_header_tab_main drag_left_20"} onClick={() => handleToggleTabs({order_type: 'requests'})}>
					<div className={(selectedTab === 'requests')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
						<span className="payment_methods_tabs_header_tab_text">Requests</span>
					</div>
					</div>
				  </div>
				  {isFeatureFlagEnabled("wallets") && 
					<div className="see_all_transactions text_right">
					   <span>View all</span>
					</div>
				  } 
				</div>
				<table className="main_table">
				  {(selectedTab === 'open_orders')?  <Openorders /> : <Requests /> }
				</table>
                   
			</div>	
		</div>	
	  
	  </>	

	);
};
export default Wallets;
