import { Calendar, Plus, Search } from "lucide-react";
import "./reports.scss";
import ReactDatePicker from "react-datepicker";
import cn from "classnames";
import { useState } from "react";
import ReactSelect from "react-select";
import { useRates } from "../../providers/Rates/RatesProvider";
import TransactionsHistory from "./transactionsHistory/transactionsHistory";
import CounterpartyTransactions from "./counterpartyTransactions/counterpartyTransactions";
import Volume from "./volume/volume";
import ProfitandLoss from "./profitAndLoss/profitAndLoss";
import AddTransaction from "./addTransaction/addTransaction";
import { useReportFilters } from "./reportsFilterProvider";

const reactSelectStyleReports = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: ".9rem",
    fontWeight: "600",
    padding: ".2rem",
    borderRadius: "8px",
    width: "100%",
    color: "#172c50",
    backgroundColor: "#ffffff",
    borderColor: "#3E9CF3",
  }),
};

const REPORTS_TABS = {
  TRANSACTIONS_HISTORY: "TRANSACTIONS_HISTORY",
  COUNTER_PARTY_TRANSACTIONS: "COUNTER_PARTY_TRANSACTIONS",
  PROFIT_AND_LOSS: "PROFIT_AND_LOSS",
  VOLUME: "VOLUME",
};

const emptyReportsState = {
  activeTab: REPORTS_TABS.TRANSACTIONS_HISTORY,
};

const Reports = () => {
  const { currencies } = useRates();
  const { updateFilters } = useReportFilters();
  const [reportsState, setReportsState] = useState(emptyReportsState);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const switchToTab = (tab) => {
    setReportsState({ ...reportsState, activeTab: tab });
  };

  const handleSearchChange = (e) => {
    updateFilters({ searchQuery: e.target.value });
  };

  const handleDateChange = (update) => {
    setDateRange(update);
    updateFilters({ startDate: update[0], endDate: update[1] });
  };

  const handleBuyCurrencyChange = (selectedOption) => {
    updateFilters({ buyCurrency: selectedOption?.value || null });
  };

  const handleSellCurrencyChange = (selectedOption) => {
    updateFilters({ sellCurrency: selectedOption?.value || null });
  };

  return (
    <div id="reports_page">
      <div className="heading">
        <div className="title">Reports</div>
      </div>
      <div className="search_inputs">
        <div className="search_input">
          <div className="label">Search</div>
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for currency, user... etc"
              onChange={handleSearchChange}
            />
            <Search className="icon" />
          </div>
        </div>
        <div className="search_input">
          <div className="label">Date</div>
          <div className="input date_picker_container">
            <ReactDatePicker
              className="date_picker"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              isClearable={true}
              placeholderText="xx/xx/xxxx - xx/xx/xxxx"
            />
            <Calendar className="icon" />
          </div>
        </div>
        <div className="search_input currency_picker_container">
          <div className="label">Currency</div>
          <div className="input currency">
            <ReactSelect
              className="currency_picker"
              styles={reactSelectStyleReports}
              options={currencies.map((c) => ({ value: c.code, label: c.code }))}
              isClearable={true}
			        onChange={handleBuyCurrencyChange}
            />
			<ReactSelect
              className="currency_picker"
              styles={reactSelectStyleReports}
              options={currencies.map((c) => ({ value: c.code, label: c.code }))}
			        isClearable={true}
              onChange={handleSellCurrencyChange}
            />
          </div>
        </div>
        <div className="search_input add_transaction_container hidden">
          <div className="label ghost_label">Add transaction btn</div>
          <button className="add_transaction_btn " popovertarget="add_transaction_popover" popovertargetaction="show">
            <Plus />
            <div className="label">Add transaction</div>
          </button>
          <div id="add_transaction_popover" popover="auto">
            <AddTransaction />
          </div>
        </div>
      </div>
      <div className="body">
        <div className="reports_tab_list">
          <div
            onClick={() => switchToTab(REPORTS_TABS.TRANSACTIONS_HISTORY)}
            className={cn("tab", { active: reportsState.activeTab === REPORTS_TABS.TRANSACTIONS_HISTORY })}
          >
            Transactions History
          </div>
          <div
            onClick={() => switchToTab(REPORTS_TABS.COUNTER_PARTY_TRANSACTIONS)}
            className={cn("hidden tab", { active: reportsState.activeTab === REPORTS_TABS.COUNTER_PARTY_TRANSACTIONS })}
          >
            Counter-party Transactions Report
          </div>
          <div
            onClick={() => switchToTab(REPORTS_TABS.PROFIT_AND_LOSS)}
            className={cn("hidden tab", { active: reportsState.activeTab === REPORTS_TABS.PROFIT_AND_LOSS })}
          >
            Profit and Loss Report
          </div>
          <div
            onClick={() => switchToTab(REPORTS_TABS.VOLUME)}
            className={cn("hidden tab", { active: reportsState.activeTab === REPORTS_TABS.VOLUME })}
          >
            Volume Report
          </div>
        </div>
        
        <div className="tab_content">
          {reportsState.activeTab === REPORTS_TABS.TRANSACTIONS_HISTORY && <TransactionsHistory />}
          {reportsState.activeTab === REPORTS_TABS.COUNTER_PARTY_TRANSACTIONS && <CounterpartyTransactions />}
          {reportsState.activeTab === REPORTS_TABS.PROFIT_AND_LOSS && <ProfitandLoss />}
          {reportsState.activeTab === REPORTS_TABS.VOLUME && <Volume />}
        </div>
      </div>
    </div>
  );
};

export default Reports;
