import React, { useState, useEffect } from "react";
import { CustomScroll } from "react-custom-scroll";
import "../../../../model.scss";
import Select from 'react-select';
import { usePaymentMethods } from "../../../../../../providers/PaymentMethodsProvider";
import { useWallets } from "../../../../../../providers/WalletsProvider";
import { formatAmount } from "../../../../../../common/formatAmount";

const MobileMoneyDeposit = ({ depositMethod, depositCountry, depositCurrency, depositAmount, openModelFunc, modelContent }) => {

    const { getWallet } = useWallets();
    const { paymentMethods, reloadPaymentMethods } = usePaymentMethods();
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState({});
    const [modelState, setModelState] = useState(modelContent?.reloadContent || false);
    const [marketplaceState, setMarketplaceState] = useState({
                                                                phonenumbers: [],
                                                                currency: depositCurrency,
                                                                amount: depositAmount,
                                                                depositMethod: depositMethod,
                                                                country: depositCountry,
                                                            });

    const initCollection = async () => {
        try {

			await reloadPaymentMethods();
            const walletBalance = await getWallet({ currency: depositCurrency });
            if (walletBalance?.currency) {
                setMarketplaceState((prevState) => ({
                    ...prevState,
                    totalBalance: walletBalance?.balance,
                    accountWalletFrom: walletBalance?.id,
                }));
            }

            const walletCollection = paymentMethods.filter((w) => w.currency === depositCurrency && w.type === 'PHONE_NUMBER');
            const defaultWalletOptions = [{ value: "", label: "" }];
            setSelectedPhoneNumber({});
            setMarketplaceState((prevState) => ({
                ...prevState,
                accountWallets: defaultWalletOptions,
            }));

            if (walletCollection?.length > 0) {
                const accountsOptions = [{ value: "", label: "Select payment method" }].concat(
                    walletCollection.map((wallet) => ({
                        value: wallet.id,
                        label: `${wallet.phonenumber} - ${wallet.phonenumberLabel}`,
                    }))
                );
                setMarketplaceState((prevState) => ({
                    ...prevState,
                    phonenumbers: accountsOptions,
                }));
                setSelectedPhoneNumber(accountsOptions[0]);
            } else {
                const nodataContent = {
                    value: "",
                    label: `You have no ${depositCurrency} phone number/s`,
                };
                setMarketplaceState((prevState) => ({
                    ...prevState,
                    phonenumbers: [nodataContent],
                }));
                setSelectedPhoneNumber(nodataContent);
            }

            setMarketplaceState((prevState) => ({
                ...prevState,
                currency: depositCurrency,
                amount: depositAmount,
            }));
        } catch (e) {
            console.log("Error section ", e);
        }
    };

    useEffect(() => {
        initCollection();
    }, [depositAmount, depositMethod, depositCountry]);

    const changePhoneNumber = (options) => {
        setSelectedPhoneNumber(options);
    };

    const addPhoneNumber = () => {
        openModelFunc({
            operation_type: 'add_phone_number',
            operation_type_step_2: 'crediting',
            operation_method_type_step_2: 'deposit',
            data: marketplaceState,
        });
    };

    const onInputChange = (field, e) => {
		setMarketplaceState((prevState) => ({
		  ...prevState,
		  [field]: e.target.value, // Dynamically update the field passed in the argument.
		}));
	};

    return (
        <div className="market_crypto_deposit">

            <div className="market_trading_scroll"  style={{height: "240px"}}>
              <CustomScroll className="custom_scrollbar" id="modelContentTopDiv" heightRelativeToParent="100%" allowOuterScroll="true">
  
                    <div className="input_block form_input_field padding_top_30">
                        <label>Phone number</label>
                        <Select
                            value={selectedPhoneNumber}
                            className="input_select"
                            onChange={changePhoneNumber}
                            options={marketplaceState.phonenumbers}
                            isSearchable={true}
                        />
                        <div className="add_new_paymentmethod_button column_100" onClick={addPhoneNumber}>
                            <button>Add a new phone number</button>
                        </div>
                    </div>

                    <div className="pop_model_section_blocks">
                        <div className="input_block form_input_field">
                            <label>Amount</label>	
                            <input className="column_100 input hide_pointer_number" 
                                value={marketplaceState?.amount} 
                                onChange={(e) => onInputChange("amount", e)} 
                                type="number" placeholder="Enter amount to deposit" />
                        </div>
                    </div> 

              </CustomScroll>
            </div>

            <div className="input_block_details">
               <div>
                <p>
                 Amount: <span>{`${formatAmount(depositAmount)} ${depositCurrency}`}</span>
                </p>
               </div>
               <div>
                <p>
                 Trx Fee: <span>{`${formatAmount(marketplaceState?.transactionFee)} ${depositCurrency}`}</span>
                </p>
               </div>
               <div className="add_new_button column_100">
                <button>Place order</button>
               </div>
            </div>

        </div>
    );
};

export default MobileMoneyDeposit;
