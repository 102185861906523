import React, { useState, useEffect } from "react";
import { Loader2, Info }     from "lucide-react";
import { CustomScroll }      from "react-custom-scroll";
import Select                from 'react-select';
import { useRates }          from "../../../providers/Rates/RatesProvider";
import { countries }         from "../../../common/countries";
import { usePaymentMethods } from "../../../providers/PaymentMethodsProvider";
import successIcon           from "../../../assets/successful.svg"; 

import api from "../../../api";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../providers/AuthProvider";


import "./payment_methods.scss";
const AddBankAccount = ({modelContent, sideModel, openModelFunc}) => {
	const { authTokens } = useAuth();
	const { currencies } = useRates();
	const { fetchPaymentOptions, paymentOptions, reloadPaymentMethods} = usePaymentMethods();
	const [selectedTab, setSelectedTab] = useState("my account");
	const [selectedCurrency, setSelectedCurrency] = useState({}); 
	const [successPosted,  setSuccessPosted] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState({}); 
	const [selectedBankName, setSelectedBankName] = useState({}); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const [dataPlaceState, setDataPlaceState] = useState({
															type:           "BANK_ACCOUNT",
                                                            accountName:    "",
															accountNumber:  "",
															address:        "",
															buildingNumber: "",
															city:           "",
															region:         "",
															swiftCode:      "",
															beneficiaryAddress: "",
															bankName:       "",
															currency:       "",
															country:        "",
															currencyOptions: [],
															buyCurrencyFilter: [],
															sellCurrencyFilter: [],
															countries: [],
															bankNames: []
														});

	const [sideMenuTab, setSideMenuTab] = useState(false); 
	const init = async (options) => {
        try{

			const defaultCurrency       = (modelContent?.data?.currency !== undefined)? modelContent?.data?.currency : "UGX";
			await fetchPaymentOptions();
			let sideModelValue  =	(sideModel !== undefined)? sideModel : false;
			setSideMenuTab(sideModelValue);

		    const currencyOptions = currencies.filter((currency) => currency.isCrypto === false).map((currency) => ({
				value: currency.code,
				label: currency.code,
			}));
			if(currencyOptions.length > 0){

				setDataPlaceState((prevState) => ({
						...prevState,
						currencyOptions: currencyOptions,
				}));

				const defaultCurrencyObject =  currencyOptions.filter((option) => option.value === defaultCurrency);
				let selectedCurrencyVal     = defaultCurrencyObject[0];
				setSelectedCurrency(selectedCurrencyVal);
			}


			// build country options
		    const countryOptions = countries.map((country) => ({
																		label: country?.name,
																		value: country?.code,
																}));
			if(countryOptions.length > 0){
					
				setDataPlaceState((prevState) => ({
														...prevState,
														countries: countryOptions
													 }));
				
			    const defaultCountry = countries.filter((country) => country?.currency?.code === defaultCurrency);	 								 
				const DefaultCountry  = countryOptions.filter((option) => option.value === defaultCountry[0]?.code);								
				setSelectedCountry(DefaultCountry[0]);
			}

		} catch(e){
			console.log("logging", e)
		}
	}	


	const initCountry = async (options) => {
        try{

			// build country options
			const countryOptions = countries.map((country) => ({
																		label: country?.name,
																		value: country?.code,
																}));
			if(countryOptions.length > 0){
					
				setDataPlaceState((prevState) => ({
													...prevState,
													countries: countryOptions
												}));
				const DefaultCountry  = countryOptions.filter((option) => option.value === "UG");								
				setSelectedCountry(DefaultCountry[0]);
				await getBankOptions(DefaultCountry[0]);
		    }

		} catch(e){
			console.log("logging", e)
		}
	}	
	
	useEffect(() => {
        init({});
		initCountry();
	}, []);

	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 

	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 


	const changeDefaultCountry = async (options) => {

	   setSelectedCountry(options);	
	   getBankOptions(options);
	   const countryOption = 'country';
	   setDataPlaceState((prevState) => ({
											...prevState,
											countryOption: options.value,
										})); 
	}

	const changeDefaultCurreny = async (options) => {

	   setSelectedCurrency({value: options.value, label: options.label});
	   const currencyOption = 'currency';
	   setDataPlaceState((prevState) => ({
											...prevState,
											currencyOption: options.value,
										})); 																 
	}

	const getBankOptions = async (options) => {
       try{
		    
		    if(options?.value === undefined){
               return;
			}

		    const selectCountry   = options?.label;
			const countryCode     = options?.value;
			let   bankOptions = paymentOptions
					.filter((paymentOption) => 
						paymentOption.country_code?.toUpperCase() === countryCode?.toUpperCase() &&
						paymentOption.payment_type?.toUpperCase() === "BANK"
					)
					.map((paymentOption) => paymentOption.name);

			// All this does is de-duplicate the names
			bankOptions = new Set(bankOptions);
			bankOptions = Array.from(bankOptions);
			// Transforming the array
            bankOptions = bankOptions.map((name) => ({ value: name, label: name }));
			setDataPlaceState((prevState) => ({
													...prevState,
													bankNames: bankOptions
												}));

		}  catch(e){console.log(e)}
	};

	const bankNameChanged = (options) => {

		const bankName = options.value;
		setSelectedBankName(options);
		const swiftCodeDetails =  paymentOptions.filter((option) => option.name === options.value);  
		setDataPlaceState((prevState) => ({
											...prevState,
											swiftCode:  swiftCodeDetails?.swift_code,
											bankName:   options.label
										 }));
	};

	

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const process = useMutation({
		mutationFn: (fields) => {
			return api.addPaymentMethodBankAccount({ paymentMethod: fields, token: authTokens.IdToken});
		},
		onError: (error, variables, context) => {
            const errors = error.response.data.errors;
		},
		onSuccess: async (data, variables, context) => {

		   //Saving response content
		   const responseData = data.data.data.attributes;
		   if(data.status === 201 || data.status === 200){
			await reloadPaymentMethods();
			setSuccessPosted(true);
		   }
		},
	});


	const onSaving = (fields) => {
		fields = {
			...fields,
			country:        selectedCountry?.value,
			currency:       selectedCurrency?.value,
			accountName:    dataPlaceState?.accountName,
			accountNumber:  dataPlaceState?.accountNumber,
			address:        dataPlaceState?.address,
			buildingNumber: dataPlaceState?.buildingNumber,
			beneficiaryAddress: dataPlaceState?.beneficiaryAddress,
			city:           dataPlaceState?.city,
			region:         dataPlaceState?.region,
			swiftCode:      dataPlaceState?.swiftCode,
			bankName:       dataPlaceState?.bankName,
			type:           dataPlaceState?.type
		};
        process.mutate(fields);
	};
	  
	const onInputChange = (field, e) => {
		setDataPlaceState((prevState) => ({
		  ...prevState,
		  [field]: e.target.value, // Dynamically update the field passed in the argument.
		}));
	};

	const handleModelClose = (e) => {

		if(modelContent?.operation_method_type_step_2 !== undefined){
            openModelFunc({operation_type: modelContent?.operation_type_step_2, operation_method_type:modelContent?.operation_method_type_step_2});
		} else {
			openModelFunc();
		}
	};

	return (
		<div className="accountsettings_paymentmethods_model">

		 <div className={successPosted? "success_form": "hidden"}>
		  <div className="text_center model_title_section text_center">
			
			<h3 className="text_center"> Bank account created </h3>	
			<h4>Your {(selectedCurrency?.value !== undefined )? selectedCurrency?.value: ' '} bank account was added successfully </h4>
			<img src={successIcon} alt="successful"/>
			<div className="add_new_button column_100"> 
			  <button onClick={() => handleModelClose()}> Ok </button>
			</div>

		  </div>
		 </div>


		  <div className={!successPosted? "": "hidden"}>
		    <div className="accountsettings_paymentmethods_model_heading">Add Bank Account</div>
			<div className={sideMenuTab? "market_bank_deposit_scroll_2 padding_top_30":""}>
			<CustomScroll className="custom_scrollbar" heightRelativeToParent="100%" allowOuterScroll="true">
				
				<div className="form_input_field column_100"> 
				    <label>Currency</label>	
				    <Select 
					    value={selectedCurrency} 
						className="input_select input-x" 
						options={dataPlaceState.currencyOptions}
						{...register("currency", { required: false })}
				        onChange={changeDefaultCurreny}
						name="currency"
						 isSearchable="true" />
						{errors.currency?.type === "required" && (
						  <span className="input_error"><Info className="input_error_icon" /> Currency is required </span>
						)}  
				</div>
				<div className="form_input_field column_100"> 
				    <label>Country</label>	
				    <Select 
					    value={selectedCountry} 
					    className="input_select input-x"
						options={dataPlaceState.countries}
						{...register("country", { required: false })} 
				        onChange={changeDefaultCountry}
						isSearchable="true" />
                    {errors.country?.type === "required" && (
					  <span className="input_error"><Info className="input_error_icon" /> Country is required </span>
					)} 
				</div>
				
				<div className="form_input_field column_100"> 
				    <label className="column_100  text-left">Bank name</label>
				    <Select value={selectedBankName} className="input_select input-x" 
						options={dataPlaceState.bankNames} 
						name="bankName"
						{...register("bankName", { required: false })}
						onChange={bankNameChanged} isSearchable="true" />
				 	{errors.bankName?.type === "required" && (
					  <span className="input_error"><Info className="input_error_icon" /> Bank name is required </span>
					)} 		
				</div>

				<div className="form_input_field column_100"> 
					<label className="column_100  text-left">Add holder name</label>
					<input className="column_100 input" type="text" placeholder="Add holder name" 
					       value={dataPlaceState.accountName}
						   name="accountName"
						   {...register("accountName", { required: true })}
						   onChange={(e) => onInputChange("accountName", e)}/>
					{errors.accountName?.type === "required" && (
					  <span className="input_error"><Info className="input_error_icon" /> Account name is required </span>
					)} 		   
				</div>
				<div className="form_input_field column_100"> 
					<label className="column_100  text-left">Bank account number</label>
					<input className="column_100 input" type="text" 
						   name="accountNumber"
					       placeholder="Add bank account number" 
						   {...register("accountNumber", { required: true })}
					       value={dataPlaceState.accountNumber}
						   onChange={(e) => onInputChange("accountNumber", e)}/>
					{errors.accountNumber?.type === "required" && (
					  <span className="input_error"><Info className="input_error_icon" /> Account number is required </span>
					)} 	   
				</div>
				<div className="form_input_field column_100"> 
					<label className="column_100  text-left">Swift Code</label>
					<input className="column_100 input" type="text" 
					       placeholder="Add swift Code"
						   name="swiftCode"
						   {...register("swiftCode", { required: false })}
					       value={dataPlaceState.swiftCode}
						   onChange={(e) => onInputChange("swiftCode", e)}  />
				</div>

				<div className="form_input_field column_100"> 
					<label className="column_100  text-left">State / Region</label>
					<input className="column_100 input" type="text" 
					       placeholder="Add state / region"
						   name="region"
						   {...register("region", { required: true })}
					       value={dataPlaceState.region}
						   onChange={(e) => onInputChange("region", e)} />
					{errors.region?.type === "required" && (
					  <span className="input_error"><Info className="input_error_icon" /> Bank region is required </span>
					)} 	 	   
				</div>
				<div className="form_input_field column_100"> 
					<label className="column_100  text-left">City</label>
					<input className="column_100 input" type="text" 
					       placeholder="Add city" 
						   name="city"
						   {...register("city", { required: true })}
					       value={dataPlaceState.city}
						   onChange={(e) => onInputChange("city", e)}/>
					{errors.city?.type === "required" && (
					  <span className="input_error"><Info className="input_error_icon" /> Bank city is required </span>
					)} 	   
				</div>
				<div className="form_input_field column_100"> 
					<label className="column_100  text-left">Bank address</label>
					<input className="column_100 input" type="text" 
					       placeholder="Add bank address" 
						   name="address"
						   {...register("address", { required: true })}
					       value={dataPlaceState.address}
						   onChange={(e) => onInputChange("address", e)}/>
					{errors.address?.type === "required" && (
					  <span className="input_error"><Info className="input_error_icon" /> Bank address is required </span>
					)} 	   
				</div>

				<div className="form_input_field column_100 hidden"> 
					<label className="column_100  text-left">Building number</label>
					<input className="column_100 input" type="text" 
					       placeholder="Add building number"
						   name="buildingNumber"
						   {...register("buildingNumber", { required: false })}
					       value={dataPlaceState.buildingNumber}
						   onChange={(e) => onInputChange("buildingNumber", e)}  />
				</div>

				<div className="form_input_field column_100"> 
					<label className="column_100  text-left">Beneficiary address</label>
					<input className="column_100 input" type="text" 
					       placeholder="Add beneficiary address"
						   name="beneficiaryAddress"
						   {...register("beneficiaryAddress", { required: false })}
					       value={dataPlaceState.beneficiaryAddress}
						   onChange={(e) => onInputChange("beneficiaryAddress", e)}  />
				</div>

				


				<div className="form_input_field column_100 hidden"> 
					<label className="column_100  text-left">Email</label>
					<input className="column_100 input" 
					       value={dataPlaceState.email}
						   onChange={(e) => onInputChange("email", e)}
						   {...register("email", { required: false })}
					       type="text" placeholder="Add email" />
					{errors.email?.type === "required" && (
					  <span className="input_error"><Info className="input_error_icon" /> Email address is required </span>
					)}  	
			  </div>
			  </CustomScroll>	
			</div>
			
			<div className="add_new_button column_100"> 
			   {process.isPending && <button> <Loader2 className="processing_loading" /> </button>}
			   {!process.isPending && <button onClick={handleSubmit(onSaving)}> Add bank account </button>}
			</div>

		  </div>
		</div>
	);
};
export default AddBankAccount;
